.SettlementInformation {
  background: #f3f3f3;
}
.SettlementInformation .itemLabel {
  min-width: 7rem;
}
.SettlementInformation .el-link {
  margin: 0 10px;
}
.SettlementInformation .sessions p {
  line-height: 25px;
  cursor: pointer;
}
.SettlementInformation .sessions .addclass {
  color: #409EFF;
}
.testInformation {
  background: #f3f3f3;
}
.studentMation {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.studentMation .el-form-item {
  width: 50%;
  margin-bottom: 5px;
}
.studentMation .el-form-item .el-form-item__label {
  line-height: 20px;
}
.studentMation .el-form-item .el-form-item__content {
  line-height: 20px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8;
}
